/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@ng-select/ng-select/themes/default.theme.css";


:root {
  --ion-color-success: #3bb145;
  --ion-color-tertiary: #5c3af1;

}

/* loading */

.loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 5px solid #7898ff;
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    position: relative;
    top: 50%;
    left: calc(50% - 30px);
  }
  
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* */

  .error{
    color: rgb(200, 20, 20) !important;
  }
  .warning{
    color:  rgb(200, 100, 20) !important;
  }
  .info{
    color: rgb(20, 100, 200) !important;
  }
  .success{
    color: #3bb145 !important;
  }




@font-face {

    font-family: 'aovel' ;
    src: url('/assets/fonts/aovel.ttf') format('truetype');
  
  }
  @font-face {
  
    font-family: 'sonika' ;
    src: url('/assets/fonts/Sonika-Bl_PERSONAL.ttf') format('truetype');
  }

  @font-face {
    font-family: 'associate_sans';
    src: url('assets/fonts/AssociateSansLight.otf') format('opentype');
  }